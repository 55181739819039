














import { Vue, Component, Prop } from 'vue-property-decorator'
import { NewstickerEntry } from '~base/common/types'
import NewsTickerArticle from '~components/content/post-types/NewsTickerArticle.vue'

@Component({
  components: { NewsTickerArticle },
})
export default class NewsTicker extends Vue {
  @Prop({ type: String, default: null }) readonly tag!: string
  @Prop({ type: Array, default: null })
  readonly posts!: NewstickerEntry[]

  @Prop({ type: String, default: '' }) readonly title!: string
  @Prop({ type: String, default: 'Mehr Nachrichten' })
  readonly buttonLabel!: string

  get enhancedTitle() {
    return `<span>${this.title}</span>ticker`
  }
}
