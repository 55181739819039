












import { Vue, Component, Prop } from 'vue-property-decorator'
import { NewstickerEntry } from '~base/common/types'
import PostTitle from '~components/content/PostTitle.vue'

@Component({
  components: {
    PostTitle,
  },
})
export default class NewsTickerArticle extends Vue {
  @Prop({ type: Object, default: null }) readonly post!: NewstickerEntry
}
