









import { Vue, Component, Prop } from 'vue-property-decorator'
import { NewstickerData, NewstickerEntry } from '~base/common/types'
import { enhance } from '~plugins/utils'
import NewsTicker from '~components/content/NewsTicker.vue'

@Component({
  components: {
    NewsTicker,
  },
})
export default class NewsTickerWidget extends Vue {
  @Prop({ type: Number, default: 5 }) readonly amount!: number
  @Prop({ type: String, default: null }) readonly tag!: string
  @Prop({ type: Object, default: null })
  readonly initialData!: NewstickerData

  @Prop({ type: String, default: '' }) readonly title!: string
  @Prop({ type: String, default: null }) readonly buttonLabel!: string

  private fetchedPosts: NewstickerEntry[] | null = null

  async mounted() {
    if (this.initialData === null) {
      await this.$apolloProvider.defaultClient
        .query({
          query: require('~base/graphql/queries/FetchApaPosts.gql'),
          variables: { first: this.amount },
        })
        .then(({ data }) => {
          this.fetchedPosts = data.apaPosts.nodes.map((p) => {
            p.type = 'apa-ticker'
            p = enhance(p, this.$config)
            return {
              postId: p.postId,
              type: p.type,
              id: p.id,
              slug: p.slug,
              title: p.title,
              dateGmt: p.dateGmt,
              modifiedGmt: p.modifiedGmt,
              link: p.link,
            }
          })
        })
    }
  }

  get posts() {
    if (this.initialData) {
      return this.initialData.search.nodes.map((p) => enhance(p, this.$config))
    }

    return this.fetchedPosts
  }
}
